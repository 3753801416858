import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldKakania: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Kakania?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_kakania.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Kakania?</h1>
          <h2>
            Not sure if pulling Kakania is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>09/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="kakania"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          We’ve said this a lot since 1.8, but probably yes. Kakania is
          considered one of the best defensive Survival units in the game, with
          plenty of offensive power and buffing to add. Her primary content is
          Raids, where her DMG Redirection provides a very powerful defensive
          anchor and her continuous Genesis DMG quickly ramps up for high
          personal damage. Kakania strikes the right chord where characters like
          Ezra and Ms NewBabel fail, being one of the few (if not the only)
          universally viable Tanks.
        </p>
        <SectionHeader title="Lore" />
        <p>
          Kakania dreams of healing the entire city with her own mirror, even
          though she never got a formal license to practice. The mirror can
          reflect problems hidden deep into people's hearts, and her diagnosis
          and treatment method focus on helping patients understand their true
          selves and desires. But what mirror has she used to reflect herself?
          The Storm has fallen over Vienna, washing away all traces equally and
          extinguishing the fire in Kakania's heart. Her era has been completely
          destroyed, and she has to regard herself as guilty, but who can
          respond to her atonement? Maybe the answer will be found in another
          era, but the guilt will forever coexist with the survivors.
        </p>
        <SectionHeader title="Review" />
        <p>
          Kakania is a 6-star Arcanist of the Plant Afflatus. One of the first
          properly defined "Tanks" in the game, Kakania is argued to be one of
          the top ranking Support/Sustain units. Her kit is geared towards
          soaking damage from allies and using it to deal tons of damage back
          and supercharging your Damage Dealer.
        </p>

        <p>
          When allies take damage, Kakania redirects 50% of it to herself,
          storing a part of it as [Empathy] (up to a cap) which is the core of
          her kit. Whenever she stores a certain amount of [Empathy], she heals
          all allies based on her own HP, and whenever Kakania herself is
          healed, she deals Genesis DMG to all enemies based on her [Empathy].
        </p>
        <p>
          But this stacking buff is used for a lot more. It increases the
          Genesis DMG dealt by "Subconscious", which is her 1-target attack
          incantation. In addition, the Genesis DMG dealt by "Id, Ego and
          Superego" (her Ultimate) is massively increased based on [Empathy]
          stacks; this incantation also records the damage enemies take after
          casting it, dealing a second instance of Genesis DMG at the end of the
          round based on the recorded damage.
        </p>
        <p>
          More importantly, [Empathy] benefits "Association", which consumes
          Kakania's HP (if cast on an ally) but provides a large ATK buff called
          [Solace] to an ally that increases its potency based on her [Empathy].
          If it is cast on herself, it restores a large chunk of HP instead.
        </p>
        <h6>Gameplay:</h6>
        <p>How does one play Kakania? </p>
        <p>
          Fun fact, Kakania's code name roughly translates to "Shitland". That's
          a ballsy name for a rights activist.
        </p>
        <p>
          Ignoring that: straight up redirecting 50% of allied damage taken is
          probably the most effective form of tanking we have had so far; it
          doesn't rely on continuous casting like Shields and functions in all
          content unlike [Taunt]. With Kakania, gone are the days that allies
          randomly get exploded by bursts of high incoming damage.
        </p>
        <p>
          Now, defensive supports usually aren't favored in gachas (unless
          obscenely overpowered) due to the rule of "the best mitigation is
          killing something before it kills you", so why does Kakania work? Two
          things: Genesis DMG and [Solace].
        </p>
        <p>
          Her Genesis DMG output is pretty wild when paired with a passive
          healer like Tooth Fairy or Vila, and her Ultimate allows her to
          exploit extremely high bursts of damage coming from units such as
          Windsong or Spathodea to absolutely erase any health bar standing
          between you and the victory screen.
        </p>
        <p>
          Then there's [Solace]. ATK buffs are few and far in between due to how
          high-value they are. Not only does Kakania have an extremely
          consistent one, it provides a flat 300 ATK + a percentage of ATK based
          on [Empathy], meaning that as the fight goes on it becomes stronger
          and stronger.
        </p>
        <p>
          This all does mean that she is typically only used in long-form
          content, and her presence isn't as required in quick burst fights
          (i.e. UTTU/Story), but as more and more new content is introduced that
          can facilitate her presence (especially stuff like high diff
          Roguelike), Kakania continues to see increases in viability (even at
          her already high status).
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Absorbs 50% of allied damage taken, making your team
                    naturally quite tanky.
                  </li>
                  <li>Very powerful ATK buff in [Solace].</li>
                  <li>
                    High personal DMG output regardless of the fight since its
                    almost entirely Genesis DMG.
                  </li>
                  <li>
                    Extremely powerful in long-form content (i.e. Raids) where
                    you can stack [Empathy].
                  </li>
                  <li>
                    Not tied to specific gimmicks, so can be slotted into pretty
                    much every team.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Wants to ramp [Empathy] over time, so not as good in short
                    content.
                  </li>
                  <li>
                    Team/Gimmick-specific supports may win out on occasion
                    depending on the team.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Kakania works fine at P0.</p>
        <p>
          However, her Portraits are quite viable. Her main one is Portrait 2,
          which allows her to sustain your team properly. Her other ones are
          Portrait 3, which lets you stack more [Empathy; and Portrait 5, which
          increases the damage output of her Ultimate.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Kakania has no strict “gimmick” and benefits basically every team
          she’s on. She does like passive healers who can trigger her Insights
          often and allies that benefit from ATK buffs or prefer small instances
          of healing over burst.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="marcus"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="vila"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="semmelweis"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="j"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Kakania:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="the-fighters-best-wishes"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="dawn-is-coming"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="beyond-wonderland"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          The Fighter’s Best Wishes (signature), Dawn is Coming (for healing),
          Beyond Wonderland (last resort)
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/kakania_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/kakania_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/kakania_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/kakania_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Underpaid Professors
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="6"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="kakania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Vila</strong>
              </li>
              <li>
                Typical set-up team, though more geared towards long cycles. For
                short cycles, shift 6 out for someone like Mercuria or a
                Portraited 37. This team specifically is designed to keep
                Windsong safe while she and 6 set-up a large hand for her to Ult
                off of. It does this by relying on 3 passively defensive units:
                6 applies a slew of potentially defense buffs at the start of
                the round, Vila heals passively and Kakania reduces both
                incoming damage while doing passive healing. This way you can
                safely set-up large hands for Windsong.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Imagine Playing The Game<span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="kakania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Vila</strong>
              </li>
              <li>
                Essentially a perfectly synergistic team. Lucy is one of the few
                characters who can utilize a Portrait 0 Mercuria due to
                “Conservation of Energy” counting as an Incantation, which helps
                stack [Cosmic Energy] (she also provides Incantation Might with
                it at 16+ stacks). Vila, of course, helps by providing
                Moxie/Eureka for [Advancement] and extra bonuses to the
                “Conservation of Energy” modifier while Kakania solidifies the
                defensive core of the team while adding [Solace], an ATK buff,
                on top of Lucy’s [Data Iteration].
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldKakania;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Kakania? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Kakania is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
